@import url('https://fonts.googleapis.com/css?family=Urbanist');

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  width: 100%;
  font-size: calc(10px + 2vmin);
}

.link {
    display: block;
    padding: .5rem 1rem;
    color: #3b8070;
    text-decoration: none;
    transition: color .15s ease-in-out,;
}

.link:hover {
  color: #194a3f
}

.artwork {
  color: black; /* text colour */
  margin: 25px;
}

.artwork .col {
  flex-grow: 0 !important;
}

.artwork img {
  min-height: 100px;
  max-height: 220px;
  box-shadow: 3px 3px 2px #b8b8b8;
}

.artwork video {
  min-height: 100px;
  max-height: 220px;
  box-shadow: 3px 3px 2px #b8b8b8;
}

.artworks {
  padding: 20px;
}

.add {
  margin: 50px;
}

/* ARTPAGE CSS */

.artpage .row {
  --bs-gutter-x: 0rem !important;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .artpage .row {
    flex-direction: column;
  }
}

.artpage img {
  max-height: 92vh;
  max-width: 100%;
}

.artpage video {
  max-height: 90vh;
  max-width: 100%;
}

.artpage .summary {
  padding: 30px 50px 30px 30px;
}

.artpage h1 {
  font-family: 'Urbanist', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.artpage h5 {
  font-family: 'Urbanist', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.desc1 {
  margin: 50px;
}

.video2 {
  padding: 50px;
}